<template>
  <div class="affiliate-driver-sidebar">
    <template v-if="loading">
      <v-layout
        column
        justify-space-around
        style="background-color: white; height: 300px"
      >
        <v-progress-circular
          style="margin: 0 auto"
          :size="70"
          indeterminate
          color="primary"
        />
      </v-layout>
    </template>
    <template v-else>
      <div class="affiliate-driver-sidebar--content">
        <v-form ref="form">
          <div
            v-if="driverDetails.rating"
            class="px-4 py-3"
            style="font-size: 12px"
            :style="`background-color: ${$cr.theme.blueLight}`"
          >
            <b>Rating:</b>
            {{ driverDetails.rating }}/5
          </div>
          <br />
          <v-flex class="mb-2" style="font-size: 12px" xs12>
            <b>Email:</b>
            {{ driverDetails.email }}
          </v-flex>
          <v-flex class="mb-2" style="font-size: 12px" xs12>
            <b>Phone Number:</b>
            {{ phoneFormatFilterWithInternational(driverDetails.phoneNumber) }}
          </v-flex>
          <v-flex style="font-size: 12px" xs12>
            <b>License #:</b>
            {{ licenseInfo }}
          </v-flex>
          <br />
          <v-layout row wrap class="margin-b-2">
            <v-flex xs4>
              <v-checkbox
                v-model="driverDetails.spabCertified"
                label="SPAB"
                disabled
                hide-details
                class="margin-t-0 padding-t-0"
              />
            </v-flex>
            <v-flex xs4>
              <v-checkbox
                v-model="driverDetails.nineteenACertified"
                label="19A"
                disabled
                hide-details
                class="margin-t-0 padding-t-0"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex
              v-for="(vehicleType, vehicleTypeIndex) in driverSupportedVehicles"
              :id="`driver-supported-vehicle-${vehicleType}-${vehicleTypeIndex}`"
              :key="`driver-supported-vehicle-${vehicleType}-${vehicleTypeIndex}`"
              xs4
            >
              <v-checkbox
                v-model="vehicleType.supported"
                style="margin-top: 0"
                :label="vehicleType.label"
                disabled
                hide-details
              />
            </v-flex>
          </v-layout>
          <v-divider />
          <v-flex>
            <h6>Upcoming Reservations</h6>
          </v-flex>
          <br />
          <v-layout row wrap>
            <div v-if="!upcomingReservations.length">
              <div>There are no upcoming reservations.</div>
            </div>
            <v-flex
              v-for="(upcomingReservation,
              upcomingReservationIndex) in upcomingReservations"
              :id="`driver-supported-vehicle-${upcomingReservation}-${upcomingReservationIndex}`"
              :key="`driver-supported-vehicle-${upcomingReservation}-${upcomingReservationIndex}`"
            >
              <v-layout>
                <v-flex xs1>
                  <h3 :style="`color: ${$cr.theme.lightGray}`">
                    {{ upcomingReservationIndex + 1 }}
                  </h3>
                </v-flex>
                <v-flex xs11>
                  <b>{{ `Reservation: ` }}</b>
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    :href="
                      $router.resolve({
                        name: 'reservation-detail',
                        params: {
                          id:
                            upcomingReservation.parentReservationId ||
                            upcomingReservation.reservationId,
                        },
                      }).href
                    "
                  >
                    <b>#{{ upcomingReservation.managedId }}</b>
                  </a>
                  <br class="mb-2" />
                  <b>{{ `Trip Dates: ` }}</b>
                  {{
                    formatDateRangeString(
                      upcomingReservation.startDate,
                      upcomingReservation.endDate
                    )
                  }}
                  <br class="mb-2" />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </div>
    </template>
  </div>
</template>

<script>
import drivers from '@/services/drivers'
import { mapActions } from 'vuex'
import { phoneFormatFilterWithInternational } from '@/utils/phone'
import { DateTime } from 'luxon'

export default {
  props: {
    driverId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      driverDetails: {},
      vehicleTypes: [],
      upcomingReservations: [],
      loading: false,
      driverSupportedVehicles: [],
    }
  },
  computed: {
    licenseInfo() {
      const licenseNumber = this.driverDetails.licenseNumber
      const licenseState = this.driverDetails.licenseState
        ? ` (${this.driverDetails.licenseState})`
        : ''
      return licenseNumber + licenseState
    },
  },
  async mounted() {
    this.loading = true
    try {
      await Promise.all([this.getVehicleTypes(), this.getDriverDetails()])
      this.driverSupportedVehicles = this.driverDetails.driverSupportedVehicles.map(
        (vehicle) => {
          const vehicleType = this.vehicleTypes.find(
            (vehicleType) => vehicleType.id === vehicle.vehicleTypeId
          )
          vehicle.label = vehicleType.label
          return vehicle
        }
      )
    } catch (error) {
      this.showAlert({
        type: 'error',
        message: 'Could not retrieve info',
      })
    }
    this.loading = false
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    phoneFormatFilterWithInternational,
    async getVehicleTypes() {
      const res = await this.$store.dispatch('vehicles/getVehicleTypes', {
        page: 1,
        pageSize: -1,
      })
      this.vehicleTypes = res.data.resultList
    },
    async getDriverDetails() {
      const res = await drivers.getAffiliateDetails(this.driverId)
      this.driverDetails = res.data?.driver
      this.upcomingReservations = res.data?.upcomingReservations
    },
    formatDateRangeString(startDate, endDate) {
      return (
        DateTime.fromISO(startDate).toFormat('MM/dd/yyyy t ZZZZ') +
        ' - ' +
        DateTime.fromISO(endDate).toFormat('MM/dd/yyyy t ZZZZ')
      )
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.affiliate-driver-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 20px 40px;
  }
}
</style>
